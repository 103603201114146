import React from 'react';

/**
 * SVG Icon
 *
 * @param {String} svgString SVG code.
 * @param {String} iconClass SVG class.
 * @param {String} altText Alternate text.
 */
export default function SvgIcon(svgString, iconClass = '', altText = '') {
  return (
    <img
      alt={altText}
      className={iconClass}
      src={svgString}
    />
  );
}

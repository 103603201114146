/* eslint-disable react/no-find-dom-node */
import React, { PureComponent } from 'react';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import VideoPlaylist from '../video-playlist';
import VideoThumbnail from '../video-thumbnail';
import VideoMeta from '../video-meta';
import {
  metaPropHandler,
  VideoContentUnitProps,
  VideoProps,
} from '../nbc-jw-player/utils';

type VideoSectionProps = {
  accountId: string;
  brand: string;
  callLetters: string;
  host: string;
  station: string;
  twitter: string;
  videos: VideoContentUnitProps[];
};

const {
  wp: {
    i18n: {
      __,
    },
  },
} = window;

export default class VideoSection extends PureComponent<VideoSectionProps> {
  /**
   * Set PropTypes for this component.
   * @type {object}
   */

  render() {
    const {
      videos,
      accountId,
      brand,
      host,
      twitter,
      callLetters,
      station,
    } = this.props;

    if (! videos.length) {
      return (null);
    }

    const {
      eyebrow: {
        link: eyebrowLink,
        label: eyebrowLabel,
      },
      title: activeTitle,
      summary: videoExcerpt,
      link,
      date_string: objectDate,
      video: {
        meta: {
          video_id: videoId,
        },
      },
    } = videos[0];

    const videoPlaylist = [{
      ...metaPropHandler(videos[0]),
    }] as VideoProps[];

    return (
      <section className="video-module">
        <div className="video-module__column column--1">
          <div
            className="video-module__video"
            ref={(DOMNode) => this.setState({ player: DOMNode })} // eslint-disable-line
          >
            <VideoPlaylist
              key={videoId}
              autoplay={false}
              placeholder
              accountId={accountId}
              brand={brand}
              host={host}
              twitter={twitter}
              callLetters={callLetters}
              station={station}
              videos={videoPlaylist}
            />
          </div>
          {
            <VideoMeta
              eyebrowLink={eyebrowLink}
              eyebrowLabel={eyebrowLabel}
              publishedTime={objectDate}
            />
          }
          <h2 className="video-module__title">
            <a href={link}>
              {parse(DOMPurify.sanitize(activeTitle))}
            </a>
          </h2>
          <div className="video-module__text">
            {parse(DOMPurify.sanitize(videoExcerpt))}
          </div>
        </div>
        <div className="video-module__column column--2">
          <h3 className="video-module__column-title">{__('More', 'nbc')}</h3>
          {videos.slice(1, 5).map((video) => (
            <VideoThumbnail
              key={video.network_object_id}
              video={video}
              showMeta
              horizontal
              showIconTime={false}
            />
          ))}
        </div>
      </section>
    );
  }
}

import { Component } from 'js-component-framework';
import { renderComponent } from 'utils/reactComponentManager';
import VideoPlaylist from '../video-playlist';

/**
 * Render a video inside of a story card.
 */
export default class StoryCardVideo extends Component {
  /**
   * Start the component
   */
  constructor(config) {
    super(config);

    this.didRender = false;
    this.bind();
  }

  onClick(event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.didRender) {
      return;
    }

    const {
      children: {
        player,
      },
      element,
    } = this;

    const isFeature = ['article - featured', 'article - immersive']
      .some((tmpl) => tmpl === nbc.dataLayer.template);

    const elem = (isFeature) ?
      document.body : element;

    // Render the video player onto the target.
    renderComponent(VideoPlaylist, player);

    elem.classList.add('video-playing');

    if (isFeature) {
      elem.classList.add('no-featured-image');
    }
    this.didRender = true;
  }

  bind() {
    const {
      children: {
        player,
        trigger,
      },
    } = this;

    if (player && trigger) {
      trigger.addEventListener('click', (e) => this.onClick(e));
    }
  }
}

import StoryCardVideo from './story-card-video';

const storyCardVideoConfig = {
  name: 'storyCardVideo',
  class: StoryCardVideo,
  querySelector: {
    player: '[data-video-player]',
    trigger: '[data-video-play-trigger], .story-card__icon',
  },
  options: {},
};

export default storyCardVideoConfig;

/**
 * Get the image from a content unit by ratio and width.
 *
 * @param {Object} object Content unit object.
 * @param {String} size Image size to retrieve.
 * @return {String|Boolean}
 */
export default function getContentUnitImageRatio(object, ratio = '16:9', width = 400) { // eslint-disable-line max-len
  let image = false;

  if ('undefined' === typeof object) {
    return image;
  }

  if ('undefined' === typeof object.image) {
    return image;
  }

  const {
    image: {
      media_details: {
        sizes = {},
      } = {},
      ratios = {},
    } = {},
  } = object;

  if ('undefined' !== typeof ratios[ratio] && 'undefined' !== typeof ratios[ratio][width]) { // eslint-disable-line max-len
    image = ratios[ratio][width];
  } else if ('undefined' !== typeof sizes.full) {
    image = sizes.full.source_url;
  } else if ('undefined' !== typeof object.image.source_url) {
    image = object.image.source_url;
  }

  return image;
}

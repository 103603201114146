import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import getContentUnitImageRatio from 'utils/getContentUnitImageRatio';

import VideoIcon from '../video-icon';
import VideoMeta from '../video-meta';
import './video-thumbnail.scss';

const {
  i18n: {
    __,
  },
} = wp;

export default class VideoThumbnail extends PureComponent {
  /**
   * Set initial props.
   * @type {object}
   */
  static defaultProps = {
    showMeta: false,
    horizontal: false,
    showIconTime: true,
    setActiveVideo: false,
    activeVideo: {},
    setSlideIndex: false,
    currentSlideIndex: 0,
    fromLive: false,
  };

  /**
   * Set PropTypes for this component.
   * @type {object}
   */
  static propTypes = {
    // Function for setting the active video when clicked.
    setActiveVideo: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.bool,
    ]),
    // The current video.
    video: PropTypes.shape({}).isRequired,
    // The active video.
    activeVideo: PropTypes.shape({}),
    // Show/hide the video meta.
    showMeta: PropTypes.bool,
    // If true, the thumbnail will display beside the video info.
    horizontal: PropTypes.bool,
    // Display the video length beside the video icon.
    showIconTime: PropTypes.bool,
    // Set slide index.
    setSlideIndex: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.bool,
    ]),
    // Current slide index.
    currentSlideIndex: PropTypes.number,
    fromLive: PropTypes.bool,
  };

  /**
   * Handle clicking a video thumbnail.
   *
   * @param {Object} e The event.
   */
  handleClick = (e) => {
    const {
      setActiveVideo,
      video,
      setSlideIndex,
      currentSlideIndex,
      fromLive,
    } = this.props;

    if (fromLive) {
      return;
    }

    // Only prevent default if a function has been passed.
    if ('function' === typeof setActiveVideo) {
      e.preventDefault();
      setActiveVideo(video);
    }

    // Only prevent default if a function has been passed.
    if ('function' === typeof setSlideIndex) {
      e.preventDefault();
      setSlideIndex(currentSlideIndex);
    }
  };

  render() {
    const {
      activeVideo,
      video,
      showMeta,
      horizontal,
      showIconTime,
    } = this.props;

    const {
      date_string_short: objectDate,
      title: newTitle,
      formatted_length: videoLength,
      link,
    } = video;

    const classes = horizontal ?
      'video-thumbnail__video video-thumbnail__video--horizontal' :
      'video-thumbnail__video';

    const imageURL = getContentUnitImageRatio(video, '16:9', '400');

    return (
      <div className="video-thumbnail">
        <a
          href={link}
          onClick={this.handleClick}
        >
          <div className={classes}>
            {imageURL &&
              <div className="video-thumbnail__image-wrapper">
                <img
                  loading="lazy"
                  src={imageURL}
                  alt={parse(DOMPurify.sanitize(newTitle))}
                />
                <VideoIcon videoLength={videoLength} showTime={showIconTime} />
                {
                  activeVideo.network_object_id === video.network_object_id ?
                    <div className="video-thumbnail__now-playing">
                      {__('Now Playing', 'nbc')}
                    </div>
                    : null
                }
              </div>
            }
            <div className="video-thumbnail__info">
              {showMeta &&
                <VideoMeta
                  eyebrowLabel={video.eyebrow.label}
                  eyebrowLink={video.eyebrow.link}
                  publishedTime={objectDate}
                />
              }
              <h4 className="video-thumbnail__video-title">
                {parse(DOMPurify.sanitize(newTitle))}
              </h4>
            </div>
          </div>
        </a>
      </div>
    );
  }
}

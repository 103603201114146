import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import VideoSection from './video-section';

import './module-styles.scss';

/**
 * Homepage Top Videos Module
 */
const VideoModule = (props) => (
  <Fragment key="topVideoModule">
    {props &&
    <VideoSection {...props} />
    }
  </Fragment>
);

VideoModule.propTypes = {
  videos: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default VideoModule;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../svg-icon';
import playIcon from '../../images/icons/icon-play.svg';
import playIconFilled from '../../images/icons/icon-play-filled.svg';
import './video-icon.scss';

/**
 * This component will display the play icon that typically overlays a video thumbnail.
 */
export default class VideoIcon extends PureComponent {
  /**
   * Set initial props.
   * @type {object}
   */
  static defaultProps = {
    videoLength: '',
    showTime: true,
  };

  /**
   * Set PropTypes for this component.
   * @type {object}
   */
  static propTypes = {
    videoLength: PropTypes.string,
    showTime: PropTypes.bool,
  };

  render() {
    const { videoLength, showTime } = this.props;

    return (
      <div className="video-icon">
        {SvgIcon(playIcon, 'play-icon-transparent', 'play video')}
        {SvgIcon(playIconFilled, 'play-icon-filled', 'play video')}
        {
          showTime &&
            <span className="video-length">
              {0 !== videoLength ? videoLength : ''}
            </span>
        }
      </div>
    );
  }
}

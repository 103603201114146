import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import classNames from 'classnames';

import './video-meta.scss';

/**
 * Displays tag and the published date of video.
 */
export default class VideoMeta extends PureComponent {
  /**
   * Set initial props.
   * @type {object}
   */
  static defaultProps = {
    eyebrowLabel: '',
    eyebrowLink: '',
    eyebrowSponsored: '',
    publishedTime: '',
    fastEnabled: false,
    fastEyebrow: '',
  };

  /**
   * Set PropTypes for this component.
   * @type {object}
   */
  static propTypes = {
    eyebrowLink: PropTypes.string,
    eyebrowLabel: PropTypes.string,
    eyebrowSponsored: PropTypes.string,
    publishedTime: PropTypes.string,
    fastEnabled: PropTypes.bool,
    fastEyebrow: PropTypes.string,
  };

  render() {
    const {
      eyebrowLink,
      eyebrowLabel,
      publishedTime,
      eyebrowSponsored,
      fastEnabled,
      fastEyebrow,
    } = this.props;

    const metaClasses = classNames({
      'video-meta': true,
      'video-meta__sponsored': eyebrowSponsored,
    });

    return (
      <div className={metaClasses}>
        {
          (fastEnabled && fastEyebrow)
            ? (
              <span className="video-meta-fast">
                {fastEyebrow}
              </span>
            ) :
            (
              <>
                {eyebrowSponsored &&
                <span className="video-meta__tag">
                  {eyebrowLabel}
                </span>
                }
                {(eyebrowLink && eyebrowLabel && ! eyebrowSponsored) &&
                <span className="video-meta__tag">
                  <a href={eyebrowLink}>
                    {eyebrowLabel}
                  </a>
                </span>
                }
                {(publishedTime && ! eyebrowSponsored) &&
                <span className="video-meta__time">
                  {parse(DOMPurify.sanitize(publishedTime))}
                </span>
                }
              </>
            )
        }
      </div>
    );
  }
}

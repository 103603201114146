/**
 * Homepage global JS.
 */
// Instantiate components
// Components
import { ComponentManager } from 'js-component-framework';
import domContentLoaded from 'utils/domContentLoaded';
import reactComponentManager from 'utils/reactComponentManager';
import VideoModule from 'components/video-module';
import storyCardVideoConfig from 'components/story-card-video';
import NBCTruncate from 'components/nbc-truncate';

// Import scss
import 'scss/core/index.scss';
import 'scss/cards/index.scss';
import 'scss/home/index.scss';

const manager = new ComponentManager('nbc-home-components');

domContentLoaded(() => {
  manager.initComponents([
    storyCardVideoConfig,
  ]);

  reactComponentManager({
    VideoModule,
    NBCTruncate,
  });
});

if (module.hot) {
  module.hot.accept();
  // This is required for HMR, otherwise you'll need to refresh
  // the page to see JS changes
  manager.initComponents([
    storyCardVideoConfig,
  ]);

  reactComponentManager({
    VideoModule,
    NBCTruncate,
  });
}
